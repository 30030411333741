import {
  TabContent,
  TabPane,
  Row,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  CardFooter,
  CardBody,
  Alert,
  Card,
} from "reactstrap"
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation"
import React, { useState, useEffect } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { useHistory, useParams } from "react-router-dom"

import { getPermsReq } from "../../../services/axios/axios.default_permissions"
import {
  getDynPermReq,
  updateDynPerm,
} from "../../../services/axios/axios.perm_groups"
import { setNavValues } from "../../../store/nav_breadcrumbs/actions"
import SmallSwitch from "../../../components/shared/smallSwitch"
import { Styles } from "../../default/user/user_table"
import config from "../../../config/index"
import { getApplicationReq, updateApp } from "../../../services/axios/axios.app"

const ApplicationEditPage = () => {
  //setState block=======================================================================
  //role constructor input states
  const [groupName, setGroupName] = useState("")
  const [email, setEmail] = useState("")
  const [website, setWebsite] = useState("")
  const [phone, setPhone] = useState("")
  const [clientId, setClientId] = useState("")
  const [clientSecret, setClientSecret] = useState("")
  const [permissions, setPermissions] = useState([])


  const [permType, setPermType] = useState("")
  const [groupDesc, setGroupDesc] = useState("")
  //data from backend
  const [defPermsStatic, setDefPermsStatic] = useState([])
  const [defPermsToRender, setDefPermsToRender] = useState([])
  const [permsArr, setPermsArr] = useState([])
  ///other
  const [activeTab, setActiveTab] = useState("1")
  const [alertStatus, setAlertStatus] = useState(false)
  const [alertStatusWarn, setalertStatusWarn] = useState(false)
  const [alertText, setalertText] = useState("")
  const [permGroupIDforUpdate, setPermGroupIDforUpdate] = useState("")

  const history = useHistory()
  const params = useParams()
  //on change handlers=========================================================================
  const nameOnChange = e => {
    setGroupName(e.target.value)
  }

  const descOnChange = e => {
    setGroupDesc(e.target.value)
  }

  const typeOnChange = e => {
    const type = e.target.value
    setPermType(type)
    const permsWithChecked = []
    permsArr.map(perm => {
      perm.isChecked = false
      if (perm.type === type) {
        permsWithChecked.push(perm)
      }
    })
    setActiveTab("2")
    setDefPermsToRender(permsWithChecked)
    setDefPermsStatic(permsWithChecked)
  }

  const searchFilterCreate = e => {
    const searchString = e.target.value
    if (searchString === "") {
      setDefPermsToRender(defPermsStatic)
      return
    }

    const filteredArr = defPermsStatic.filter(perm =>
      perm?.name.toLowerCase().includes(searchString.toLowerCase())
    )
    setDefPermsToRender(filteredArr)
  }

  const permsOnChange = (value, id, isChecked) => {
    const filteredArr = defPermsToRender
    const elementOnCheck = filteredArr.find(perm => perm._id === id)
    elementOnCheck.isChecked = !isChecked
    setDefPermsToRender([...filteredArr])
    // setMagicRender(!magicRender)
  }
  ///async handlers
  //on save modal handler
  const saveChanges = async () => {
    if (!groupName) {
      setActiveTab("1")
      return
    }
    const reqObj = {
      name: groupName,
      _id: permGroupIDforUpdate,
      email: email,
      website: website,
      phone: phone,
      description: groupDesc,
      permissions: permissions,
    }
    const response = await updateApp(reqObj)
    if (response.data === "succesfully updated") {
      setActiveTab("1")
      setAlertStatus(true)
    } else {
      setalertStatusWarn(true)
      setalertText(
        response?.data?.message
          ? response?.data?.message
          : "Something went wrong"
      )
      setTimeout(() => {
        setalertStatusWarn(false)
      }, 5000)
    }
  }
  /////async block===========================================================

  ///get default perms
  const getPerms = async () => {
    const permission = await getApplicationReq(params.id)
    const state = permission.data;

    setGroupName(state.name)
    setGroupDesc(state.description)
    setWebsite(state.website)
    setPhone(state.phone)
    setEmail(state.email)
    setPermissions(state.permissions)
    setClientId(state.cred.clientId)
    setClientSecret(state.cred.clientSecret)
    setPermGroupIDforUpdate(state["_id"])
  }

  const unassignedPerm = defPermsToRender?.map((perm, index) => {
    if (!perm.isChecked && perm.is_active) {
      return (
        <Row className="perm-check" key={"right-switch" + perm._id}>
          <SmallSwitch perm={perm} permsOnChange={permsOnChange} />
        </Row>
      )
    }
  })

  //useEffect block
  useEffect(() => {
    getPerms()
    config.reduxStore.dispatch(
      setNavValues({
        mainTitle: config.breadCrumbsFirstTitle,
        title1: "Application",
        title2: "Edit",
      })
    )
  }, [])

  //inner components

  return (
    <Container className="p-0 user-main-wrapper" fluid>
      <Styles className=" styles-inner-pages-wrapper">
        <Container fluid className="edit-user-wrapper hide-scrollbar">
          <AvForm>
            <div className="modal-body">
              <Col className="user-data-inputs">
                <Nav tabs className="nav-tabs-custom">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={activeTab === "1" ? "active" : ""}
                      onClick={() => {
                        setActiveTab("1")
                      }}
                    >
                      <span className="d-none d-sm-block">General</span>
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab} className="p-3 text-muted">
                  <TabPane tabId="1">
                    <Row>
                      <Col sm="12">
                        <Card>
                          <CardBody className="mb-0">
                            <div className="mb-3">
                              <AvField
                                name="name"
                                label="Name  "
                                value={groupName}
                                onChange={nameOnChange}
                                placeholder="Add name"
                                type="text"
                                errorMessage="Add name"
                                validate={{
                                  required: { value: true },
                                }}
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="email"
                                label="Email  "
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                placeholder="Add email"
                                type="text"
                                errorMessage="Add email"
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="website"
                                label="Website  "
                                value={website}
                                onChange={e => setWebsite(e.target.value)}
                                placeholder="Add website"
                                type="text"
                                errorMessage="Add website"
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="phone"
                                label="Phone  "
                                value={phone}
                                onChange={e => setPhone(e.target.value)}
                                placeholder="Add phone"
                                type="text"
                                errorMessage="Add phone"
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="description"
                                label="Description"
                                value={groupDesc}
                                onChange={descOnChange}
                                placeholder="Add description (not required)"
                                type="text"
                                validate={false}
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="cred.clientId"
                                label="clientId"
                                value={clientId}
                                type="text"
                                disabled
                                validate={false}
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="cred.clientSecret"
                                label="clientSecret"
                                disabled
                                value={clientSecret}
                                type="text"
                                validate={false}
                              />
                            </div>
                            <div className="mb-3">
                              <h4>Permissions</h4>
                              <div className="mb-3">
                                <AvCheckboxGroup
                                  inline
                                  name="activities"
                                  label="Activities"
                                >
                                  <AvCheckbox
                                    label="Read"
                                    value="activities:read"
                                    checked={permissions.includes("activities:read")}
                                    onChange={(e) => {
                                      if(permissions.includes(e.target.value)) {
                                        setPermissions([...permissions.filter(p => p != e.target.value)]) 
                                      } else {
                                        setPermissions([...permissions,e.target.value])
                                      }
                                    }}
                                  />
                                  <AvCheckbox
                                    label="Write"
                                    value="activities:write"
                                    checked={permissions.includes("activities:write")}
                                    onChange={(e) => {
                                      if(permissions.includes(e.target.value)) {
                                        setPermissions([...permissions.filter(p => p != e.target.value)]) 
                                      } else {
                                        setPermissions([...permissions,e.target.value])
                                      }
                                    }}
                                  />
                                </AvCheckboxGroup>
                              </div>
                            </div>
                          </CardBody>
                          <CardFooter>
                            <button
                              type="button"
                              onClick={() => {
                                history.push("../application/")
                              }}
                              className="btn btn-secondary waves-effect"
                              data-dismiss="modal"
                            >
                              Back
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary waves-effect waves-light"
                              onClick={saveChanges}
                            >
                              Update Application
                            </button>
                          </CardFooter>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </Col>
            </div>
          </AvForm>
          {alertStatus ? (
            <SweetAlert
              className="alert-perm-group"
              title="Success!"
              success
              isAnimation={true}
              showCancel
              confirmBtnText="Return"
              cancelBtnText="Continue"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="primary"
              onConfirm={() => {
                history.push("../application")
              }}
              onCancel={() => {
                setAlertStatus(false)
              }}
            >
              Application updated!
            </SweetAlert>
          ) : null}
          {alertStatusWarn && (
            <Alert color="warning" style={{ position: "absolute", bottom: 50 }}>
              <strong>Oops!</strong> {alertText}
            </Alert>
          )}
        </Container>
      </Styles>
    </Container>
  )
}

export default ApplicationEditPage
