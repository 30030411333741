import {
  Alert,
  Button,
  Container
} from "reactstrap"

import Switch from "react-switch"
import {
  createDefPermReq,
  deactivatePermReq,
  deletePermReq,
  updatePermReq
} from "../../../services/axios/axios.default_permissions"
import React, { useState } from "react"
import { useEffect } from "react"
import SweetAlert from "react-bootstrap-sweetalert"

import "./default-permissions.page.scss"
import { setNavValues } from "../../../store/nav_breadcrumbs/actions"
import { Styles } from "../user/user_table"
import { Offsymbol, OnSymbol } from "../../../components/shared/switchSymbolsDefault"
import { Table } from "../../../components/shared/reactTable"
import CreateDefaultPermModal from "./modals/createModal"
import EditDefaultPermModal from "./modals/editModal"
import { columnsContentDefaultPermissions, getPerms } from "./default-permissions.table"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import { setSortStateAc } from "../../../store/table/sort/actions"
import useThrottle from "../../../services/shared_handlers/debouncer"
import config from "../../../config"

export default function DefaultPermissionsPage() {
  //states
  const [data, setData] = React.useState([])
  const [pageCount, setPageCount] = React.useState(0)
  const [loading, setLoading] = React.useState(false)
  const [modalShow, setModalShow] = useState(false)
  const [nameFilterP, setNameFilterP] = useState("")
  const [typeFilterP, setTypeFilterP] = useState("")
  const [editModalShow, setEditModalShow] = useState(false)
  const [realNameFilter, setRealNameFilter] = useState("")
  const [activateFilterStatus, setActivateFilterStatus] = useState(false)
  const [permIdForDelete, setPermIdForDelete] = useState("")
  const [magicRender, setMagicRender] = useState(false)
  const [activateStatus, setActivateStatus] = useState(true)
  const [activateID, setActivateID] = useState("")
  const [alertStatus, setAlertStatus] = useState(false)
  const [alertText, setAlertText] = useState("")
  const [alertStatusWarn, setAlertStatusWarn] = useState(false)
  const [deleteAlertStatus, setDeleteAlertStatus] = useState(false)
  const [deactivateAlertStatus, setDeactivateAlertStatus] = useState(false)
  const [activateAlertSTatus, setActivateAlertStatus] = useState(false)
  const [permForEdit, setPermForEdit] = useState({
    _id: "",
    name: "",
    description: "",
    type: "",
    is_internal: true
  })

  const [permForCreate, setPermForCreate] = useState({
    name: "",
    description: "",
    type: "",
    is_internal: ""
  })

  //consts
  const history = useHistory()
  //select sort value
  const sortVal = useSelector(state => state.sort.sortBy)
  //dispatch sorting
  const onSortedChange = React.useCallback((sortBy) => {
    config.reduxStore.dispatch(setSortStateAc(sortBy[0]))
  }, [])
  const [debounce] = useThrottle(1000)

  //async handlers block
  const fetchData = React.useCallback(async ({ pageSize, pageIndex }) => {
    setLoading(true)
    const startRow = pageSize * pageIndex
    const {
      permsToPush,
      amount
    } = await getPerms(
      startRow, pageSize,
      sortVal, realNameFilter,
      activateFilterStatus, reactivatePermission,
      setModalShow, editPermission,
      deletePermission, typeFilterP,
      history
    )
    setData(permsToPush)
    setPageCount(Math.ceil(amount / pageSize))
    setLoading(false)
  }, [
    activateFilterStatus, magicRender, realNameFilter, typeFilterP, sortVal
  ])

  //functions with request========================================================
  const deletePermissionStep2 = async () => {
    const response = await deletePermReq(permIdForDelete)
    if (response && response?.data === "deleted successfully") {
      setDeleteAlertStatus(false)
      setMagicRender(!magicRender)
      setAlertText("Permission deleted")
      setAlertStatus(true)
      setTimeout(() => {
        setAlertStatus(false)
      }, 2000)
    } else {
      setDeleteAlertStatus(false)
      setAlertStatusWarn(true)
      setTimeout(() => {
        setAlertStatusWarn(false)
      }, 2000)
      setAlertText(response.data.message)
    }
  }

  const reactivatePermissionStep2 = async () => {
    const response = await deactivatePermReq(activateID, activateStatus)
    setDeactivateAlertStatus(false)
    setActivateAlertStatus(false)
    if (response && response?.data === "reactivated successfully") {
      if (activateStatus === "true") {
        setAlertStatusWarn(true)
        setTimeout(() => {
          setAlertStatusWarn(false)
        }, 2000)
        setAlertText("Permission deactivated")
      } else {
        setAlertText("Permission activated")
        setAlertStatus(true)
        setTimeout(() => {
          setAlertStatus(false)
        }, 2000)
      }
    } else {
      setAlertStatusWarn(true)
      setTimeout(() => {
        setAlertStatusWarn(false)
      }, 2000)
      setAlertText(response.data.message)
    }
    setMagicRender(!magicRender)
  }

  ///=========================================================Edit, Reactivate, Delete

  //setting perm for edit modal
  const editPermission = (id, permsArr) => {
    const currentPerm = permsArr.find(perm => perm._id === id)
    setPermForEdit({
      _id: currentPerm._id,
      name: currentPerm.name,
      description: currentPerm.description,
      type: currentPerm.type,
      is_internal: currentPerm.is_internal?.toString()
    })
    setEditModalShow(true)
  }

  const deletePermission = (e, id) => {
    setPermIdForDelete(id)
    setDeleteAlertStatus(true)
  }

  const reactivatePermission = (e, id, status) => {
    setActivateID(id)
    setActivateStatus(status)
    if (status === "true") {
      setDeactivateAlertStatus(true)
    } else {
      setActivateAlertStatus(true)
    }
  }

  ///Table filters==================================================

  const clearFilters = () => {
    setNameFilterP("")
    setTypeFilterP("")
    setRealNameFilter("")
    setActivateFilterStatus(false)
  }
  const selectTypeFilter = e => {
    const target = e.target
    const value = target?.value
    if (value === "default") {
      setTypeFilterP("")
      return
    }
    setTypeFilterP(value)
  }

  ///=================Create Modal Handlers
  const updatePermission = async (permForEdit) => {
    const response = await updatePermReq(permForEdit)
    if (response?.data === "updated successfully") {
      setAlertText("Permission updated")
      setAlertStatus(true)
      setTimeout(() => {
        setAlertStatus(false)
      }, 2000)
    } else {
      setAlertStatusWarn(true)
      setTimeout(() => {
        setAlertStatusWarn(false)
      }, 2000)
      setAlertText(response.data.message)
    }
    setEditModalShow(false)
    setMagicRender(!magicRender)
    setPermForEdit({
      _id: "",
      name: "",
      description: "",
      type: "",
      is_internal: true
    })
  }

  const saveChangesCreate = async () => {
    if (
      !permForCreate.name ||
      !permForCreate.type ||
      !permForCreate.description
    ) {
      return
    }
    const response = await createDefPermReq(permForCreate)

    if (response?.data === "created successfully") {
      setMagicRender(!magicRender)
      setModalShow(false)
      setAlertText("Permission created")
      setAlertStatus(true)
      setTimeout(() => {
        setAlertStatus(false)
      }, 2000)
    } else {
      setModalShow(false)
      setAlertStatusWarn(true)
      setTimeout(() => {
        setAlertStatusWarn(false)
      }, 5000)
      setAlertText(response.data.message)
    }
    setPermForCreate({ name: "", description: "", type: "", is_internal: "" })
  }

  const createFormInputs = e => {
    const target = e.target
    const value = target.value
    switch (target.name) {
      case "name":
        permForCreate.name = value
        setPermForCreate(permForCreate)
        break
      case "desc":
        permForCreate.description = value
        setPermForCreate(permForCreate)
        break
      case "type":
        permForCreate.type = value
        setPermForCreate(permForCreate)
        break
      case "usage-type":
        permForCreate.is_internal = value
        setPermForCreate(permForCreate)
    }
  }

  const editFormInputs = e => {
    const target = e.target
    const value = target.value
    switch (target.name) {
      case "name":
        permForEdit.name = value
        setPermForEdit(permForEdit)
        break
      case "desc":
        permForEdit.description = value
        setPermForEdit(permForEdit)
        break
      case "type":
        permForEdit.type = value
        setPermForEdit(permForEdit)
        break
      case "usage-type":
        permForEdit.is_internal = value
        setPermForEdit(permForEdit)
    }
  }
  ////==========================
  //useEffect block============================================================
  useEffect(() => {
    config.reduxStore.dispatch(setNavValues({ mainTitle: config.breadCrumbsFirstTitle, title1: "", title2: "Default Permissions" }))
    const state = history.location?.state
    if (!!state && state.isModalRequired) {
      setModalShow(true)
    }
  }, [])


  //=========================================================

  return (
    <Container fluid className="providers-main-wrapper">
      <CreateDefaultPermModal
        modalShow={modalShow}
        setModalShow={setModalShow}
        editFormInputs={createFormInputs}
        saveChangesCreate={saveChangesCreate}
      />
      <EditDefaultPermModal
        editModalShow={editModalShow}
        setEditModalShow={setEditModalShow}
        editFormInputs={editFormInputs}
        permForEdit={permForEdit}
        updatePermission={updatePermission}
      />
      {deleteAlertStatus ? (
        <SweetAlert
          title="Attention"
          success={false}
          showCancel
          confirmBtnText="No"
          cancelBtnText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="warning"
          onConfirm={() => {
            setDeleteAlertStatus(false)
          }}
          onCancel={() => {
            deletePermissionStep2()
          }}
        >
          Are you sure want to delete this permission?
          <br />
          Deletion cannot be reverted.
        </SweetAlert>
      ) : null}
      {deactivateAlertStatus ? (
        <SweetAlert
          title="Attention"
          success={false}
          showCancel
          confirmBtnText="No"
          cancelBtnText="Deactivate"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="warning"
          onConfirm={() => {
            setDeactivateAlertStatus(false)
          }}
          onCancel={() => {
            reactivatePermissionStep2()
          }}
        >
          Are you sure want to deactivate this permission? .
        </SweetAlert>
      ) : null}
      {activateAlertSTatus ? (
        <SweetAlert
          title="Attention"
          success={false}
          showCancel
          confirmBtnText="Activate"
          cancelBtnText="No"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="warning"
          onConfirm={() => {
            reactivatePermissionStep2()
          }}
          onCancel={() => {
            setActivateAlertStatus(false)
          }}
        >
          Are you sure want to activate this permission? .
        </SweetAlert>
      ) : null}
      <Styles className="provider-white-card">
        <div className="card-title-desc user-table-filter-block mb-3">
          <div>
            <div className="col-md-12">
              <input
                value={nameFilterP}
                className="form-control sub-inputs"
                placeholder="Search by title"
                type="text"
                onChange={
                  (e) => {
                    setNameFilterP(e.target.value)
                    debounce(() => () => setRealNameFilter(e.target.value))
                  }
                }
              />
            </div>

          </div>
          <div>
            <div className="col-md-12">
              <select
                className="form-control sub-inputs"
                onChange={selectTypeFilter}
                value={typeFilterP}
              >
                <option value="default">Search By Type</option>
                <option value="role" key="role">
                  Roles
                </option>
                <option value="subscription" key="subscription">
                  Subscriptions
                </option>
              </select>
            </div>
          </div>

          <div className="clear-button me-5">
            <Button
              color="secondary"
              className="waves-effect waves-light"
              onClick={clearFilters}
            >
              Clear Filters
            </Button>
          </div>
          <div className="active-add-block">
            <div className="switch-component pb-1">
              <div className="mb-1">Show Deactivated</div>
              <Switch
                uncheckedIcon={<Offsymbol />}
                checkedIcon={<OnSymbol />}
                onColor="#626ed4"
                value={activateFilterStatus}
                onClick={e => {
                }}
                onChange={e => {
                  setActivateFilterStatus(!activateFilterStatus)
                }}
                checked={activateFilterStatus}
              />
            </div>
            <i
              onClick={() => {
                setModalShow(true)
              }}
              className="mdi mdi-bookmark-plus add-icon sub-add-btn"
            ></i>
          </div>
        </div>
        <Table
          columns={columnsContentDefaultPermissions}
          data={data}
          pageCount={pageCount}
          fetchData={fetchData}
          loading={loading}
          onSort={onSortedChange}
        />
      </Styles>
      {alertStatus && (
        <div className="alert-wrapper">
          <Alert color="success" className="bg-success text-white success-custom alert-card">
            <strong>Well done!</strong> {alertText}
          </Alert>
        </div>
      )}
      {alertStatusWarn && (
        <div className="alert-wrapper">
          <Alert color="warning" className="bg-warning text-white success-custom alert-card">
            <strong>Oops!</strong> {alertText}
          </Alert>
        </div>
      )}
    </Container>)
}
