import {

  Alert,
  Button,
  Container,
} from "reactstrap"

import Switch from "react-switch"
import React, { useState } from "react"
import { useEffect } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import {
  deleteDynPermReq, reactivateDynPerm
} from "../../../services/axios/axios.perm_groups"

import { useHistory } from "react-router-dom"
import { setNavValues } from "../../../store/nav_breadcrumbs/actions"
import { Table } from "../../../components/shared/reactTable"
import { columnsContentPermGroups, getPermGroups } from "./permission-group.table"
import { useSelector } from "react-redux"
import useThrottle from "../../../services/shared_handlers/debouncer"
import { setSortStateAc } from "../../../store/table/sort/actions"
import { Styles } from "../user/user_table"
import { Offsymbol, OnSymbol } from "../../../components/shared/switchSymbolsDefault"
import config from "../../../config"

export default function PermissionGroupsPage() {
  //useState block

  const [magicRender, setMagicRender] = useState(false)
  const [alertStatus, setAlertStatus] = useState(false)
  const [alertStatusWarn, setAlerWarnStatus] = useState(false)
  const [alertText, setAlertText] = useState("")
  const [deleteAlertStatus, setDeleteAlertStatus] = useState(false)
  const [data, setData] = React.useState([])
  const [pageCount, setPageCount] = React.useState(0)
  const [loading, setLoading] = React.useState(false)
  const [realNameFilter, setRealNameFilter] = useState("")
  const [activateFilterStatus, setActivateFilterStatus] = useState(false)
  const [permGroupIdForReactivate, setPermGroupIdForReactivate] = useState('')
  const [permGroupIdForDelete,setPermGroupIdForDelete] = useState('')
  const [activateAlertStatus,setActivateAlertStatus]= useState(false)
  const [deactivateAlertStatus, setDeactivateAlertStatus]= useState(false)

  const history = useHistory()
  const sortVal = useSelector(state => state.sort.sortBy)
  const [debounce] = useThrottle(1000)

  //dispatch sorting
  const onSortedChange = React.useCallback((sortBy) => {
    config.reduxStore.dispatch(setSortStateAc(sortBy[0]))
  }, [])




  //fill table
  const fetchData = React.useCallback(async ({ pageSize, pageIndex }) => {
    setLoading(true)
    const startRow = pageSize * pageIndex
    const {
      groupsToPush,
      amount
    } = await getPermGroups(
      startRow, pageSize,
      sortVal, realNameFilter,
      activateFilterStatus,
      setActivateAlertStatus,setDeactivateAlertStatus,
      setPermGroupIdForReactivate,setPermGroupIdForDelete,
    setDeleteAlertStatus,history
    )
    setData(groupsToPush)
    setPageCount(Math.ceil(amount / pageSize))
    setLoading(false)
  }, [
    sortVal, realNameFilter,
     activateFilterStatus, magicRender
  ])


  //handlers
  async function deleteGroup( permGroupIdForDelete) {

const response = await deleteDynPermReq(permGroupIdForDelete)
    if (response?.data?.id) {
      setDeleteAlertStatus(false)
      setAlerWarnStatus(true)
      setAlertText("Permission Group deleted")
      setTimeout(() => {
        setAlerWarnStatus(false)
      }, 2000)
      setMagicRender(!magicRender)
    }
  }

async function reactivateGroup(permGroupIdForReactivate) {
  const response = await reactivateDynPerm(permGroupIdForReactivate)
  if(response?.data&&response.data?.message==='success'){
    if(response.data.result){
      setAlertText('Permission Group activated')
      setAlertStatus(true)
      setTimeout(() => {setAlertStatus(false)},2000)
    }else{
      setAlertText('Permission Group deactivated')
      setAlerWarnStatus(true)
      setTimeout(() => {setAlerWarnStatus(false)},2000)
    }
  }else{
    setAlertText('Unknown error. Please contact administrator.')
    setAlerWarnStatus(true)
    setTimeout(() => {setAlerWarnStatus(false)},2000)
  }
  setActivateAlertStatus(false)
  setDeactivateAlertStatus(false)
  setMagicRender(!magicRender)
}


  //filter handlers
  const nameFilter = e => {
    debounce(() => () => setRealNameFilter(e.target.value))
  }

  ///clear all filter
  const resetFilters = () => {
    setRealNameFilter("")
    setActivateFilterStatus(false)
  }



  //useeffectsect
  useEffect(() => {

    config.reduxStore.dispatch(setNavValues({mainTitle:config.breadCrumbsFirstTitle,title2:'Permission Groups',title1:''}))

  }, [magicRender])


  return(
    <Container fluid className="providers-main-wrapper">
      {deleteAlertStatus ? (
        <SweetAlert
          title="Attention"
          success={false}
          showCancel
          confirmBtnText="No"
          cancelBtnText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="warning"
          onConfirm={() => {
            setDeleteAlertStatus(false)
          }}
          onCancel={() => {
            deleteGroup( permGroupIdForDelete)
          }}
        >
          Are you sure want to delete this permission group?
          <br />
          Deletion cannot be reverted.
        </SweetAlert>
      ) : null}
      {deactivateAlertStatus ? (
        <SweetAlert
          title="Attention"
          success={false}
          showCancel
          confirmBtnText="No"
          cancelBtnText="Deactivate"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="warning"
          onConfirm={() => {
            setDeactivateAlertStatus(false)
          }}
          onCancel={() => {
            reactivateGroup(permGroupIdForReactivate)
          }}
        >
          Are you sure want to deactivate this group?
        </SweetAlert>
      ) : null}
      {activateAlertStatus ? (
        <SweetAlert
          title="Attention"
          success={false}
          showCancel
          confirmBtnText="Activate"
          cancelBtnText="No"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="warning"
          onConfirm={() => {
            reactivateGroup(permGroupIdForReactivate)
          }}
          onCancel={() => {
            setActivateAlertStatus(false)
          }}
        >
          Are you sure want to activate this group?
        </SweetAlert>
      ) : null}

      <Styles className="provider-white-card">
        <div className="card-title-desc user-table-filter-block mb-3">
          <div>
            <div className="col-md-12">
              <input
                // ref={inputTitle}
                className="form-control sub-inputs"
                placeholder="Search by title"
                type="text"
                onChange={nameFilter}
              />
            </div>
          </div>
          <div className="clear-button me-5">
            <Button
              color="secondary"
              className="waves-effect waves-light"
              onClick={resetFilters}
            >
              Clear Filters
            </Button>
          </div>
          <div className="active-add-block">
            <div className="switch-component pb-1">
              <div className="mb-1">Show Deactivated</div>
              <Switch
                uncheckedIcon={<Offsymbol />}
                checkedIcon={<OnSymbol />}
                onColor="#626ed4"
                value={activateFilterStatus}
                onClick={e => {
                }}
                onChange={e => {
                  setActivateFilterStatus(!activateFilterStatus)
                }}
                checked={activateFilterStatus}
              />
            </div>
            <i
              onClick={() => {
                history.push("../permission-groups/create")
              }}
              className="mdi mdi-bookmark-plus add-icon sub-add-btn"
            ></i>
          </div>
        </div>
        <Table
          columns={columnsContentPermGroups}
          data={data}
          pageCount={pageCount}
          fetchData={fetchData}
          loading={loading}
          onSort={onSortedChange}
        />


      </Styles>
      {alertStatus && (
        <div className="alert-wrapper">
          <Alert color="success" className="bg-success text-white success-custom alert-card">
            <strong>Well done!</strong> {alertText}
          </Alert>
        </div>
      )}
      {alertStatusWarn && (
        <div className="alert-wrapper">
          <Alert color="warning" className="bg-warning text-white success-custom alert-card">
            <strong>Oops!</strong> {alertText}
          </Alert>
        </div>
      )}
    </Container>
  )
}
