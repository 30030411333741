import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import {
  Alert,
  Button, Card,
  CardBody,
  Col,
  Container,
  Row
} from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import { useHistory } from "react-router-dom"
import Switch from "react-switch"


import {
  createUser,
  deactivateUserReq,
  deleteUserReq,
  statsForUsersPageReq
} from "../../../services/axios/axios.user"

import { setSortStateAc } from "../../../store/table/sort/actions"
import { getRolesReq } from "../../../services/axios/axios.roles"
import { setNavValues } from "../../../store/nav_breadcrumbs/actions"
import useThrottle from "../../../services/shared_handlers/debouncer"

import chevron from "../../../assets/default/images/ico/chevronForSelect.png"
import { Offsymbol, OnSymbol } from "../../../components/shared/switchSymbolsDefault"
import { columnsContent, getTestUsers, inactiveMonthFilter, makeInvalid, Styles } from "./user_table"
import CreateUserModal from "./modalCreateUser"
import "./user.page.scss"
import '../../../assets/styles_enemy_unknown/hell_styles.scss'
import { Table } from "../../../components/shared/reactTable"
import config from "../../../config"

function UsersPage() {

  // states
  const [rolesArr, setRoles] = useState([])
  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [nameFilterP, setNameFilterP] = useState("")
  const [realNameFilter, setRealNameFilter] = useState("")
  const [pageCount, setPageCount] = React.useState(0)
  const [phoneFilterP, setPhoneFilterP] = useState("")
  const [realPhoneFilter, setRealPhoneFilter] = useState("")
  const [roleFilterP, setRoleFilterP] = useState("")
  const [activateFilterStatus, setActivateFilterStatus] = useState(false)
  const [magicRender, setMagicRender] = useState(false)
  const [userIdForDelete, setUserIdForDelete] = useState("")
  const [modalDeleteStatus, setModalDeleteStatus] = useState(false)
  const [alertText, setAlertText] = useState("")
  const [alertStatusWarn, setAlertStatusWarn] = useState(false)
  const [alertStatus, setAlertStatus] = useState(false)
  const [blockedUserAmount, setBlockedUserAmount] = useState(0)
  const [totalUserAmount, setTotalUserAmount] = useState(0)
  const [inactiveAmount, setInactiveAmount] = useState(0)
  const [createModalStatus, setCreateModalStatus] = useState(false)
  const [userName, setUserName] = useState("")
  const [userNameValid, setUserNameValid] = useState(false)
  const [userFullName, setUserFullName] = useState("")
  const [userFullNameValid, setUserFullNameValid] = useState(false)
  const [userEmail, setUserEmail] = useState("")
  const [userEmailValid, setUserEmailValid] = useState(false)
  const [userPhone, setUserPhone] = useState("")
  const [userPhoneValid, setUserPhoneValid] = useState(false)
  const [userRole, setUserRole] = useState("")
  const [userRoleValid, setUserRoleValid] = useState("")
  const [userNotes, setUserNotes] = useState("")
  const [userPassword, setUserPassword] = useState("")
  const [isLengthValid, setIsLengthValid] = useState(false)
  const [isUpperLetterValid, setIsUpperLetterValid] = useState(false)
  const [isLowerLetterValid, setIsLowerLetterValid] = useState(false)
  const [isAnyNumberValid, setIsAnyNumberValid] = useState(false)
  const [isStringAlphaNumValid, setisStringAlphaNumValid] = useState(false)
  const [isPasswordValid, setIsPasswordValid] = useState(false)
  const [matchStatus, setMatchStatus] = useState(false)
  const [confirmPasswords, setConfirmPasswords] = useState("")
  const [formValid, setFormValid] = useState(false)
  const [emailErrorStatus, setEmailErrorStatus] = useState(false)
  const [phoneErrorStatus, setPhoneErrorStatus] = useState(false)

  //consts
  const history = useHistory()
  const columns = React.useMemo(
    () => columnsContent,
    []
  )
  const sortVal = useSelector(state => state.sort.sortBy)
  const onSortedChange = React.useCallback((sortBy) => {
    config.reduxStore.dispatch(setSortStateAc(sortBy[0]))
  }, [])
  const [debounce] = useThrottle(1000)
  //temp data emulated
  const paid = 35

  //async section
  //data for table
  const fetchData = React.useCallback(async ({ pageSize, pageIndex }) => {
    setLoading(true)
    const startRow = pageSize * pageIndex
    const {
      usersForPush,
      amount
    } = await getTestUsers(startRow, pageSize,
      sortVal, realNameFilter,
      realPhoneFilter, roleFilterP,
      activateFilterStatus, goUserEditPage,
      deactivateUser, setUserIdForDelete,
      setModalDeleteStatus, rolesArr, magicRender, setMagicRender)
    const serverData = usersForPush
    setData(serverData)
    setPageCount(Math.ceil(amount / pageSize))
    setLoading(false)
  }, [sortVal, realNameFilter, realPhoneFilter, roleFilterP, activateFilterStatus, magicRender, rolesArr])

  //roles for dropdown
  const getRoles = async () => {
    const responseRoles = await getRolesReq()
    setRoles(responseRoles?.data)
  }

  ///deactivate/activate
  const deactivateUser = async id => {
    const response = await deactivateUserReq(id)
    if (response?.statusText === "OK") {
      setMagicRender(!magicRender)
      if (!!response.data.result) {
        setAlertStatus(true)
        setAlertText("User activated")
        setTimeout(() => {
          setAlertStatus(false)
        }, 2000)
      } else {
        setAlertStatusWarn(true)
        setAlertText("User deactivated")
        setTimeout(() => {
          setAlertStatusWarn(false)
        }, 2000)
      }
      return
    } else {
      setAlertStatusWarn(true)
      setAlertText("Something went wrong. Please contact administrator")
      setTimeout(() => {
        setAlertStatusWarn(false)
      }, 5000)
      console.log({ response })
    }
  }

//delete
  const deleteUser = async () => {
    const response = await deleteUserReq(userIdForDelete)
    if (response.data === "user deleted") {
      setMagicRender(!magicRender)
      setModalDeleteStatus(false)
      setAlertStatusWarn(true)
      setAlertText("User deleted")
      setTimeout(() => {
        setAlertStatusWarn(false)
      }, 2000)
    }
  }

  //set data for cards
  const setDataCards = async () => {
    const statistic = await statsForUsersPageReq()
    if (statistic && statistic.data) {
      setInactiveAmount(statistic.data?.inactive)
      setBlockedUserAmount(statistic.data?.deactivated)
      setTotalUserAmount(statistic.data?.total)
    }


  }

  //user actions
  //open create modal
  const tog_create_modal = () => {
    setCreateModalStatus(!createModalStatus)
    document.body.classList.add("no_padding")
  }

  const goUserEditPage = e => {
    const id = e.target.dataset.id
    history.push({ pathname: `../users/edit/${id}`, state: id })
  }


  //filter handling
  const resetFilters = () => {
    setNameFilterP("")
    setRealNameFilter("")
    setRoleFilterP("")
    setPhoneFilterP("")
    setRealPhoneFilter("")
    setActivateFilterStatus(false)
  }
  ///filter by phone
  const phoneFilter = e => {
    const value = e.target.value
    setPhoneFilterP(value)
  }
  ///filter by role
  const roleFilter = e => {
    const value = e.target.value
    setRoleFilterP(value)
  }


  /////////create user modal catch input values
  ///creation modal
  const editFormInputs = e => {
    const target = e.target
    const value = target.value
    switch (target.name) {
      case "login":
        setUserName(value)
        setUserNameValid(value.trim().length > 1)
        break
      case "fullname":
        setUserFullName(value)
        setUserFullNameValid(value.trim().length > 1)
        break
      case "email":
        setUserEmail(value)
        setUserEmailValid(/^(([^()[\]\\.,;:\s@"]+(\.[^()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          .test(String(e.target.value).toLowerCase()))
        break
      case "role":
        setUserRole(value)
        setUserRoleValid(value.trim().length > 1)
        break
      case "notes":
        setUserNotes(value)
        break
      case "password":
        setUserPassword(value)
        if (value.trim().length >= 8) {
          setIsLengthValid(true)
        } else {
          setIsLengthValid(false)
        }
        //upperblock
        setIsUpperLetterValid(value.toLowerCase() != value)
        //lower block
        setIsLowerLetterValid(value.toUpperCase() != value)
        //number block
        setIsAnyNumberValid(/\d/i.test(value))
        // setisStringAlphaNumValid(isAlphaNumeric(value))
        setisStringAlphaNumValid(/(?=.*?[#?!@$%^&*-])/.test(value))
        if (!!confirmPasswords) {
          setMatchStatus(value === confirmPasswords)
        }
        break
      case "password1":
        setConfirmPasswords(value)
        setMatchStatus(value === userPassword)
        break
      case "phone":
        setUserPhone(value)
        setUserPhoneValid(value.trim().length > 8&&/^([+\d].*)?\d$/.test(value))
        break
    }
  }

  ///save changes
  const saveChanges = async () => {
    try {
      const userData = {
        username: userName,
        fullname: userFullName,
        email: userEmail,
        role: userRole,
        notes: userNotes,
        password: userPassword,
        phoneNumber: userPhone
      }

      const response = await createUser(userData)
      if (response.data.message === "user created") {
        setMagicRender(!magicRender)
        tog_create_modal()
        setAlertText("User created")
        setAlertStatus(true)
        setTimeout(() => {
          setAlertStatus(false)
        }, 2000)
        setCreateModalStatus(false)
      } else if (response.data.message.includes("email")) {
        setEmailErrorStatus(true)
        setTimeout(() => {
          setEmailErrorStatus(false)
        }, 5000)
      } else if (response.data.message.includes("phone")) {
        setPhoneErrorStatus(true)
        setTimeout(() => {
          setPhoneErrorStatus(false)
        }, 5000)
      } else {
        setAlertStatusWarn(true)
        setAlertText("Something went wrong. Please contact administrator")
        setTimeout(() => {
          setAlertStatusWarn(false)
        }, 5000)
      }
    } catch (error) {
      console.log("error :>> ", error)
    }
  }


  useEffect(() => {
    setIsPasswordValid(
      isLengthValid &&
      isUpperLetterValid &&
      isLowerLetterValid &&
      isAnyNumberValid &&
      isStringAlphaNumValid &&
      matchStatus
    )
  }, [
    isLengthValid,
    isUpperLetterValid,
    isLowerLetterValid,
    isAnyNumberValid,
    isStringAlphaNumValid,
    matchStatus
  ])

  useEffect(() => {
    setFormValid(isPasswordValid
      && userFullNameValid && userEmailValid
      && userRoleValid && userPhoneValid)
  }, [
    isPasswordValid, userFullNameValid, userEmailValid
    , userRoleValid, userPhoneValid
  ])

  useEffect(() => {
    config.reduxStore.dispatch(setNavValues({ mainTitle: config.breadCrumbsFirstTitle, title2: "Users" }))
    getRoles()
    setDataCards()
  }, [])

  return (
    <Container fluid className="providers-main-wrapper">
      <Styles className="provider-white-card">
          <CreateUserModal
            createModalStatus={createModalStatus}
            setCreateModalStatus={setCreateModalStatus}
            tog_create_modal={tog_create_modal}
            editFormInputs={editFormInputs}
            rolesArr={rolesArr}
            saveChanges={saveChanges}
            emailErrorStatus={emailErrorStatus}
            phoneErrorStatus={phoneErrorStatus}
            validation={{
              isLengthValid, isUpperLetterValid,
              isLowerLetterValid, isAnyNumberValid,
              isStringAlphaNumValid, matchStatus,
              formValid
            }}
          />
          {modalDeleteStatus ? (
            <SweetAlert
              title="Attention"
              success={false}
              showCancel
              confirmBtnText="No"
              cancelBtnText="Delete"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="warning"
              onConfirm={() => {
                setModalDeleteStatus(false)
              }}
              onCancel={() => {
                deleteUser()
              }}
            >
              Are you sure want to delete this user?
            </SweetAlert>
          ) : null}
          <Row className="pt-0 mt-4 row-all-content">
            <Row className="p-0 ps-4 row-cards-block">
              <Col xl={3} md={6}>
                <Card className="mini-stat bg-warning">
                  <CardBody>
                    <div className="stats-card-title">Total Users</div>
                    <div className="stats-card-data"> {totalUserAmount}</div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={3} md={6}>
                <Card className="mini-stat bg-warning ms-1">
                  <CardBody>
                    <div className="stats-card-title">Inactive (last 30 days)</div>
                    <div className="stats-card-data"> {inactiveAmount}</div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={3} md={6}>
                <Card className="mini-stat bg-warning ms-1">
                  <CardBody>
                    <div className="stats-card-title">Paid</div>
                    <div className="stats-card-data">
                      {paid}</div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={3} md={6}>
                <Card className="mini-stat bg-warning ms-1">
                  <CardBody>
                    <div className="stats-card-title">Blocked</div>
                    <div className="stats-card-data">
                      {blockedUserAmount}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Col className="col-table-actions">
              <CardBody className="p-0">
                <div className="card-title-desc user-table-filter-block mb-0">
                  <div>
                    <div className="col-md-12">
                      <input
                        value={nameFilterP}
                        className="form-control"
                        placeholder="Search by name"
                        type="text"
                        onChange={(e) => {
                          setNameFilterP(e.target.value)
                          debounce(() => () => setRealNameFilter(e.target.value))
                        }}
                        style={{ border: "1px solid #CFD2F2", borderRadius: "4px" }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="col-md-12">
                      <input
                        placeholder="Search by phone"
                        value={phoneFilterP}
                        className="form-control"
                        type="text"
                        onChange={(e) => {
                          setPhoneFilterP(e.target.value)
                          debounce(() => () => setRealPhoneFilter(e.target.value))
                        }}
                        style={{ border: "1px solid #CFD2F2", borderRadius: "4px" }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="col-md-12 chevron-container">
                      <select
                        className="form-control"
                        onChange={roleFilter}
                        value={roleFilterP}
                        style={{ border: "1px solid #CFD2F2", borderRadius: "4px" }}
                      >
                        <option value="default">Choose Role</option>
                        {rolesArr?.map((role, index) => {
                          const string = role.name
                          return (
                            <option value={role._id} key={"role3" + index + role._id}>
                              {string?.charAt(0).toUpperCase() + string?.slice(1)}
                            </option>
                          )
                        })}
                      </select>
                      <img src={chevron} className="chevron-img-user-page" alt="" />
                    </div>
                  </div>
                  <div className="clear-button me-5">
                    <Button
                      color="secondary"
                      className="waves-effect waves-light"
                      onClick={resetFilters}
                    >
                      Clear Filters
                    </Button>
                  </div>
                  <div className="active-add-block">
                    <div className="switch-component pb-1">
                      <div className="mb-1">Show Deactivated</div>
                      <Switch
                        uncheckedIcon={<Offsymbol />}
                        checkedIcon={<OnSymbol />}
                        onColor="#626ed4"
                        onClick={e => {
                        }}
                        onChange={e => {
                          setActivateFilterStatus(!activateFilterStatus)
                        }}
                        checked={activateFilterStatus}
                      />
                    </div>
                    <i
                      onClick={() => {
                        tog_create_modal()
                        makeInvalid(setFormValid, setMatchStatus, setIsPasswordValid, setisStringAlphaNumValid,
                          setIsAnyNumberValid, setIsLowerLetterValid, setIsUpperLetterValid,
                          setIsLengthValid)
                      }}
                      className="mdi mdi-account-plus add-icon"
                      style={{ fontSize: "24px", cursor: "pointer" }}
                    ></i>
                  </div>
                </div>
              </CardBody>
            </Col>
          </Row>
          <Row className="hide-scrollbar row-table-content">
            <Col>
              <Table
                columns={columns}
                data={data}
                fetchData={fetchData}
                loading={loading}
                pageCount={pageCount}
                onSort={onSortedChange}
              />
            </Col>
          </Row>

        {alertStatus && (
          <div className="alert-wrapper">
            <Alert color="success" className="bg-success text-white success-custom alert-card">
              <strong>Well done!</strong> {alertText}
            </Alert>
          </div>
        )}
        {alertStatusWarn && (
          <div className="alert-wrapper">
            <Alert color="warning" className="bg-warning text-white success-custom alert-card">
              <strong>Oops!</strong> {alertText}
            </Alert>
          </div>
        )}
      </Styles>
    </Container>
  )
}

export default UsersPage
