import { usePagination, useSortBy, useTable } from "react-table"
import React from "react"
import { Container, Pagination, PaginationItem, PaginationLink } from "reactstrap"
import moment from "moment"

import { getDynPermsForTableReq } from "../../services/axios/axios.perm_groups"
import { getApplicaitonForTableReq } from "../../services/axios/axios.app"


//table columns
export const columnsContentPermGroups = [
  {
    Header: "ID",
    accessor: "_id"
  },
  {
    Header: "Title",
    accessor: "name"
  },
  {
    Header: "Description",
    accessor: "description"
  },
  {
    Header: "Client Id",
    accessor: "clientId"
  },
  {
    Header: "Status",
    accessor: "is_active"
  },
  {
    Header: "Date Created",
    accessor: "createdAt"
  },
  {
    Header: "Updated At",
    accessor: "updatedAt"
  },
  {
    Header: "Action",
    accessor: "action"
  }
]

///request for data
export const getPermGroups = async (skip, limit,
                               sortVal, realNameFilter,
                               activateFilterStatus,
                                    setActivateAlertStatus,setDeactivateAlertStatus,
                                    setPermGroupIdForReactivate,setPermGroupIdForDelete,
                                    setDeleteAlertStatus,history
) => {
  let results = await getApplicaitonForTableReq(
    skip, limit,
    sortVal, realNameFilter,
    activateFilterStatus
  )
  const permsFromBack = results?.data?.permGroups ? results.data.permGroups : []
  const amount = results?.data.amount ? results.data.amount : 0
  const groupsToPush = []
  if (permsFromBack && permsFromBack?.length) {
    permsFromBack.map(group => {
      let permissions = ""
      let permissionsQuantity = 0
      group?.permissionID?.forEach(perm => {
        permissionsQuantity++;
        if( permissionsQuantity<=2){
          if(permissions){
            permissions=permissions + " , " + perm.name + "..."
          }else{
            permissions=perm.name.slice(0, 20) + "..."
          }
        } else if(group.permissionID.length===permissionsQuantity){
          permissions = permissions +' and '+ (permissionsQuantity-permissions.split(',').length).toString() + ' more'
        }
      })


      groupsToPush.push({
        _id: (
          <div
            className={group.is_active ? "" : "inactive"}
            key={"id-" + group._id}
          >
            {group._id.slice(0, 10) + "..."}
          </div>
        ),
        name: (
          <div
            className={group.is_active ? "" : "inactive"}
            key={"name-" + group._id}
          >
            {group?.name}
          </div>
        ),
        description: (
          <div
            className={group.is_active ? "" : "inactive"}
            key={"desc-" + group._id}>
            {group?.description?.slice(0, 20) + "..."}
          </div>
        ),
        type: (
          <div
            className={group.is_active ? "" : "inactive"}
            key={"type-" + group._id}>
            {group?.type}
          </div>
        ),
        clientId: (
          <div
            className={group.is_active ? "" : "inactive"}
            key={"perms-" + group._id}
          >
            {group.cred?group.cred.clientId:''}
          </div>

        ),
        is_active: (
          <div
            className={group.is_active ? "" : "inactive"}
            key={"status-" + group._id}
          >
            {group?.is_active ? "active" : "inactive"}
          </div>

        ),

        createdAt: (
          <div
            className={group.is_active ? "" : "inactive"}
            key={"date-" + group._id}
          >
            {moment(group?.createdAt).format("MM/DD/YYYY")}
          </div>
        ),
        updatedAt: (
          <div
            className={group.is_active ? "" : "inactive"}
            key={"date2-" + group._id}
          >
            {group?.updatedAt
              ? moment(group?.updatedAt).format("MM/DD/YYYY")
              : "-"}
          </div>
        ),

        action: (
          <div className="action-in-table" key={"action-" + group._id}>
            <i
              key={"ico1-" + group._id}
              data-id={group._id}
              className="typcn typcn-edit perm-action"
              onClick={() => history.push("../application/" + group._id)}
            ></i>
            {group?.is_active ? (
              <i
                key={"ico2-" + group._id}
                data-id={group._id}
                onClick={e => {
                  setDeactivateAlertStatus(true)
                  setPermGroupIdForReactivate(group._id)
                }}
                className="typcn typcn-flash-outline perm-action"
              ></i>
            ) : (
              <i
                key={"ico3-" + group._id}
                className="typcn typcn-flash perm-action"
                data-id={group._id}
                onClick={e => {
                  setPermGroupIdForReactivate(group._id)
                  setActivateAlertStatus(true)
                }}
              ></i>
            )}
            <i
              key={"ico4-" + group._id}
              className="typcn typcn-delete-outline perm-action"
              data-id={group._id}
              onClick={(e) => {
                setPermGroupIdForDelete(group._id)
                setDeleteAlertStatus(true)
              }}

            ></i>
          </div>
        )

      })
    })
  }
  return { groupsToPush, amount }
}