import axios from "axios"

import config from "../../config/index"

export async function getApplicaitonForTableReq(
  skip,
  limit,
  sortVal,
  realNameFilter,
  activateFilterStatus
) {
  try {
    const response = await axios.get(
      config.axiosConnectionUrl +
        "application/" +
        "table/query?skip=" +
        skip +
        "&limit=" +
        limit +
        "&sortid=" +
        sortVal?.id +
        "&sortdesc=" +
        sortVal?.desc +
        "&namefilter=" +
        realNameFilter +
        "&activated=" +
        activateFilterStatus
    )
    return response
  } catch (error) {
    console.log(error)
    // return error.response.data.message;
  }
}

export async function genClientId() {
    try {
      const response = await axios.get(
        config.axiosConnectionUrl +
          "application/gen_token"
      )
      return response
    } catch (error) {
      console.log(error)
      // return error.response.data.message;
    }
  }
  
///create dynamic perm
export async function createApp(reqObj) {
    try {
      const response = await axios.post(config.axiosConnectionUrl+'application/app', reqObj)
      return response
    } catch (error) {
      console.log(error)
      return error.response.data.message
    }
  }
  
//delete dyn perm
export async function deleteAppReq(id) {
    try {
      const response = await axios.delete(config.axiosConnectionUrl+'application/' + id)
      return response
    } catch (error) {
      console.log(error)
      return error.response.data.message
    }
  }


export async function reactivateApp(id) {
    try {
      const response = await axios.put(config.axiosConnectionUrl+'application/' + id)
      return response
    } catch (error) {
      console.log(error)
      return error.response
    }
}
export async function getApplicationReq(id) {
  try {
    const response = await axios.get(config.axiosConnectionUrl+'application/' + id)
    return response
  } catch (error) {
    console.log(error)
    // return error.response.data.message;
  }
}



export async function updateApp(reqObj) {
  try {
    const response = await axios.put(config.axiosConnectionUrl+'application/', reqObj)
    return response
  } catch (error) {
    console.log(error)
    return error.response.data.message
  }
}