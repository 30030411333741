import realConfig from '../../../config/index'


const config = {
  axiosConnectionUrl: realConfig.axiosConnectionUrl,
  breadCrumbsFirstTitle:realConfig.breadCrumbsFirstTitle,
  pageTitleAdditional: realConfig.pageTitleAdditional,
  footerMessage: realConfig.footerMessage,
  loginPageProps: {
    title: realConfig.loginPageProps.title,
    messageH: realConfig.loginPageProps.messageH,
    messageF: realConfig.loginPageProps.messageF,
    logo: realConfig.loginPageProps.logo
  },
  reduxStore: realConfig.reduxStore,
  leftSideBarLogo: realConfig.leftSideBarLogo
}

export default config
