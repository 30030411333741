import defaultLogo from '../defaultCore/src/assets/images/logo-sm.png'
import store from "../store";
const config = {
  axiosConnectionUrl: 'https://hawkeye-api.dev.cogs.network/api/',
  breadCrumbsFirstTitle:'Hawkeye',
  pageTitleAdditional: 'Some page addition title',
  footerMessage: 'some copyright message',
  loginPageProps: {
    title: 'Hawkeye',
    messageH: 'Hawkeye',
    messageF: '',
    logo: defaultLogo
  },
  reduxStore: store,
  leftSideBarLogo: defaultLogo
}

export default config
