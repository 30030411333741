import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React from "react"

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { loginUser, apiError } from "../../store/actions"
import { signIn } from "../../services/register_flow/log_in_out"
import config from "../../config"


const loginHandler = async (a, b, history) => {

  const result = await signIn(a, b)
  if (result) {
    config.reduxStore.dispatch(loginUser(result))
    history.push("./dashboard")
  } else {
    config.reduxStore.dispatch(apiError("You are not Authorized"))
  }
}

const Login = props => {
  const history = useHistory()


  return (
    <React.Fragment>
      <MetaTags>
        <title>{config.loginPageProps.title}</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={4}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20">{config.loginPageProps.messageH}</h5>
                    <p className="text-white-50">
                      {config.loginPageProps.messageF}
                    </p>
                    <Link to="/" className="logo logo-admin">
                      <img src={config.loginPageProps.logo} height="24" alt="logo" />
                    </Link>
                  </div>
                </div>

                <CardBody className="p-4">
                  <div className="p-3">
                    <AvForm
                      className="form-horizontal mt-4"
                      onValidSubmit={(e, v) => {
                        loginHandler(e, v, history)
                      }}
                    >
                      {props.error && typeof props.error === "string" ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Password"
                          type="password"
                          required
                          placeholder="Enter Password"
                        />
                      </div>

                      <Row className="mb-3">
                        <Col sm={6}>
                          <div className="form-check">
                            {/*<input*/}
                            {/*  type="checkbox"*/}
                            {/*  className="form-check-input"*/}
                            {/*  id="customControlInline"*/}
                            {/*/>*/}
                            {/*<label*/}
                            {/*  className="form-check-label"*/}
                            {/*  htmlFor="customControlInline"*/}
                            {/*>*/}
                            {/*  Remember me*/}
                            {/*</label>*/}
                          </div>
                        </Col>
                        <Col sm={6} className="text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            Log In
                          </button>
                        </Col>
                      </Row>
                      <Row className="mt-2 mb-0 row">
                        <div className="col-12 mt-4">
                          {/*<Link to="/forgot-password">*/}
                          {/*  <i className="mdi mdi-lock"></i> Forgot your*/}
                          {/*  password?*/}
                          {/*</Link>*/}
                        </div>
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                {/*<p>*/}
                {/*  Don&#39;t have an account ?{" "}*/}
                {/*  <Link to="register" className="fw-medium text-primary">*/}
                {/*    {" "}*/}
                {/*    Signup now{" "}*/}
                {/*  </Link>{" "}*/}
                {/*</p>*/}

              </div>
            </Col>
          </Row>

        </Container>

      </div>

    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(Login)
)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
}
